export const vaellukset = [
  // {
  //   name: 'aloittelijoiden-vaellus-hetta-pallas-2023',
  //   title: 'Aloittelijoiden vaellus Hetta-Pallas 2023',
  //   dates: ['22.-25.6.2023', '20.-23.7.2023', '9.-12.9.2023', '16.-19.9.2023'],
  //   link: '/vaellukset/aloittelijoiden-vaellus-hetta-pallas-2023',
  // },
  {
    name: 'aloittelijoiden-vaellus-hetta-pallas-2024',
    title: 'Aloittelijoiden vaellus Hetta-Pallas 2024',
    dates: ['13.-16.6.2024', '20.-23.6.2024', '14.-17.9.2024', '21.-24.9.2024'],
    link: '/vaellukset/aloittelijoiden-vaellus-hetta-pallas-2024',
  },
  // {
  //   name: 'haltin-vaellus',
  //   title: 'Haltin vaellus',
  //   dates: ['28.8.-2.9.2023'],
  //   link: '/vaellukset/haltin-vaellus',
  // },
  {
    name: 'pohjois-norjan-vaellus',
    title: 'Pohjois-Norjan vaellus',
    dates: ['31.7.-4.8.2024'],
    link: '/vaellukset/pohjois-norjan-vaellus',
  },
];

export const hiihtovaellukset = [
  {
    name: 'kaamoshiihto-pallas-yllastunturin-kansallispuistossa',
    title: 'Kaamoshiihto Pallas-Yllästunturin kansallispuistossa',
    dates: ['6.-8.12.2024'],
    link: '/hiihtovaellukset/kaamoshiihto-pallas-yllastunturin-kansallispuistossa',
  },
  // {
  //   name: 'uuden-vuoden-voimaretki',
  //   title: 'Uuden vuoden voimaretki',
  //   dates: ['30.12.2023-1.1.2024'],
  //   link: '/hiihtovaellukset/uuden-vuoden-voimaretki',
  // },
  {
    name: 'aloittelijoiden-hetta-pallas-hiihtovaellus',
    title: 'Aloittelijoiden Hetta-Pallas hiihtovaellus',
    dates: ['19.-23.3.2024'],
    link: '/hiihtovaellukset/aloittelijoiden-hetta-pallas-hiihtovaellus',
  },
  {
    name: 'haltin-hiihtovaellus',
    title: 'Haltin hiihtovaellus',
    dates: ['3.-10.4.2024'],
    link: '/hiihtovaellukset/haltin-hiihtovaellus',
  },
];

export const maastopyoraily = [
  // {
  //   name: 'maastopyoravaellus-suomi-routsi-norja',
  //   title: 'Maastopyörävaellus Suomi-Ruotsi-Norja',
  //   dates: ['31.8.-3.9.2023'],
  //   link: '/maastopyoraily/maastopyoravaellus-suomi-ruotsi-norja',
  // },
];

export const vapaalasku = [
  // {
  //   name: 'naisten-finlav-lumiturvallisuus-1-yllas',
  //   title: 'Naisten FINLAV Lumiturvallisuus 1 YLLÄS',
  //   dates: ['18.-19.2.2023'],
  //   link: '/vapaalasku/naisten-finlav-lumiturvallisuus-1-yllas',
  // },
];
