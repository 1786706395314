import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import styled, { ThemeProvider } from 'styled-components';

import theme from '../../stylesheets/theme';
import Logo from '../../images/logos/White.svg';
import {
  vaellukset,
  maastopyoraily,
  hiihtovaellukset,
  vapaalasku,
} from '../../information/camps';

const BurgerStyles = styled.div`
  background: ${(props) => props.theme.darkblue};
  z-index: 1001;
  min-height: 100vh;
  width: 100vw;
  position: absolute;
  /* @media (min-width: 600px) {
    width: 400px;
  } */
  .burger-menu-container {
    padding: 10px 2vw 75px;
    display: flex;
    flex-direction: column;
    .top-bar {
      display: flex;
      flex-direction: row;
      width: 100%;
      .title {
        padding: 0;
        img {
          padding: 0 20px;
          margin: 5px 0;
          height: 30px;
          @media (min-width: 400px) {
            height: 35px;
          }
        }
      }
      .burger-container {
        margin: auto 0 auto auto;
        padding: 0 15px;
        display: flex;
        svg {
          color: ${(props) => props.theme.white};
          width: 27px;
          height: 27px;
          margin: auto 0;
          &:hover {
            cursor: pointer;
            opacity: 0.7;
          }
        }
      }
    }
    .links-column {
      display: flex;
      flex-direction: column;
      padding: 0 0 25px;
      margin: 10px auto 0;
      max-width: 800px;
      @media (min-width: 600px) {
        margin: 25px auto 0;
      }
      .link {
        margin: 5px auto 0 0;
        padding: 5px 15px;
        font-family: ${(props) => props.theme.secondFont};
        color: ${(props) => props.theme.white};
        font-weight: 500;
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
        .burger-event-title {
          font-size: 16px;
          font-weight: 500;
          text-align: left;
          @media (min-width: 500px) {
            font-size: 18px;
          }
        }
      }
      .burger-category {
        font-weight: 600;
        font-family: ${(props) => props.theme.firstFont};
        font-size: 20px;
        margin-top: 15px;
        @media (min-width: 500px) {
          font-size: 22px;
        }
      }
      .destination-link {
        display: none;
        @media (min-width: 500px) {
          display: block;
        }
      }
      .indented {
        margin-left: 15px;
      }
    }
  }
`;
/* eslint-disable */

const BurgerMenu = ({ toggleBurger, toggleNoScroll }) => (
  <ThemeProvider theme={theme}>
    <BurgerStyles>
      <div className="burger-menu-container">
        <div className="top-bar">
          <Link
            to="/#start"
            className="title"
            onClick={() => {
              toggleBurger(false);
              toggleNoScroll(false);
            }}
          >
            <img src={Logo} alt="" />
          </Link>
          <div className="burger-container">
            <FontAwesomeIcon
              icon={faAngleDoubleUp}
              onClick={() => {
                toggleBurger(false);
                toggleNoScroll(false);
              }}
            />
          </div>
        </div>
        <div className="links-column">
          <div className="link burger-category">Vaellukset</div>
          {vaellukset.map((camp) => {
            return (
              <Link to={camp.link} key={camp.title} className="link indented">
                <p className="burger-event-title">{camp.title}</p>
              </Link>
            );
          })}
          <div className="link burger-category">Hiihtovaellukset</div>
          {hiihtovaellukset.map((camp) => {
            return (
              <Link to={camp.link} key={camp.title} className="link indented">
                <p className="burger-event-title">{camp.title}</p>
              </Link>
            );
          })}
          <div className="link burger-category">Maastopyöräily</div>
          {/* {maastopyoraily.map((camp) => {
            return (
              <Link to={camp.link} key={camp.title} className="link indented">
                <p className="burger-event-title">{camp.title}</p>
              </Link>
            );
          })} */}
          <div className="link indented">
            <p className="burger-event-title">Lisätiedot tulevat pian</p>
          </div>
          <div className="link burger-category">Vapaalasku</div>
          {/* {vapaalasku.map((camp) => {
            return (
              <Link to={camp.link} key={camp.title} className="link indented">
                <p className="burger-event-title">{camp.title}</p>
              </Link>
            );
          })} */}
          <div className="link indented">
            <p className="burger-event-title">Lisätiedot tulevat pian</p>
          </div>
          <br />
          <Link
            to="/tietoa-meista"
            className="link burger-category"
            onClick={() => {
              toggleBurger(false);
              toggleNoScroll(false);
            }}
          >
            Tietoa meistä
          </Link>
          <br />
          <Link
            to="/usein-kysytyt-kysymykset"
            className="link burger-category"
            onClick={() => {
              toggleBurger(false);
              toggleNoScroll(false);
            }}
          >
            Usein kysytyt kysymykset
          </Link>
        </div>
      </div>
    </BurgerStyles>
  </ThemeProvider>
);

export default BurgerMenu;
