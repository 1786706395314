import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhoneSquare,
  faEnvelopeSquare,
} from '@fortawesome/free-solid-svg-icons';
import {
  faInstagram,
  faFacebookSquare,
  // faTripadvisor,
} from '@fortawesome/free-brands-svg-icons';

import styled, { ThemeProvider } from 'styled-components';

import TikTok from '../images/logos/tiktok-logo.svg';

import theme from '../stylesheets/theme';

const FooterStyles = styled.div`
  margin-top: 100px;
  min-height: 20vh;
  padding: 75px 5vw 50px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.lightgrey};
  @media (min-width: 425px) {
    padding: 75px 35px 50px;
  }
  .partners-title {
    margin: 0 auto 35px;
    text-align: center;
    font-size: 16px;
  }
  .footer-logo-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90vw;
    margin: 0 auto 35px;
    height: 15vw;
    @media (min-width: 525px) {
      margin: 0 auto 75px;
    }
    @media (min-width: 725px) {
      height: 100px;
    }
    @media (min-width: 800px) {
    }
    @media (min-width: 875px) {
      width: 800px;
    }
    /* padding: 5px 0; */
    .footer-logo {
      margin: 5px 0;
      height: 10vw;
      width: 10vw;
      @media (min-width: 725px) {
        height: 80px;
        width: 80px;
      }
      @media (min-width: 800px) {
        height: 100px;
        width: 100px;
      }
    }
    .footer-logo-pallas {
      margin: 5px 0;
      height: 10vw;
      width: calc(10vw * 1.26);
      @media (min-width: 725px) {
        height: 80px;
        width: calc(80px * 1.26);
      }
      @media (min-width: 800px) {
        height: 100px;
        width: calc(100px * 1.26);
      }
    }
    .footer-logo-green {
      margin: 5px 0;
      height: 10vw;
      width: calc(10vw * 1.1391470749);
      @media (min-width: 725px) {
        height: 80px;
        width: calc(80px * 1.1391470749);
      }
      @media (min-width: 800px) {
        height: 100px;
        width: calc(100px * 1.1391470749);
      }
    }
    .footer-logo-kilpis {
      margin: 5px 0;
      height: 10vw;
      width: calc(10vw * 1.28);
      @media (min-width: 725px) {
        height: 80px;
        width: calc(80px * 1.28);
      }
      @media (min-width: 800px) {
        height: 100px;
        width: calc(100px * 1.28);
      }
    }
  }
  .footer-info-row {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    @media (min-width: 525px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    @media (min-width: 690px) {
      display: grid;
      grid-template-columns: 33.333% 33.333% 33.333%;
    }
    /* flex-direction: row; */
    .contact-column {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      @media (min-width: 525px) {
        margin: 0;
      }
      .contact-us {
        margin: 75px auto;
        margin-bottom: 10px;
      }
      .contact-with {
        /* width: 100%; */
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        svg {
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }
        .contact-phone,
        .contact-email {
          font-size: 14px;
          margin: auto 0;
          color: ${(props) => props.theme.black};
          font-family: ${(props) => props.theme.firstFont};
        }
      }
    }
    .social-row {
      margin: auto auto;
      display: none;
      flex-direction: row;
      @media (min-width: 690px) {
        display: flex;
      }
      .social-link {
        display: flex;
        svg,
        .tiktok {
          width: 25px;
          height: 25px;
          &:hover {
            opacity: 0.7;
          }
        }
        .tiktok {
          margin-left: 2.5px;
        }
        svg:nth-child(1) {
          margin: auto 5px;
        }
      }
    }
    .terms-column {
      display: flex;
      flex-direction: column;
      margin: 25px auto 0;
      @media (min-width: 525px) {
        margin: auto 0;
      }
      .terms-link {
        margin: 2.5px auto;
        cursor: pointer;
        text-align: left;
        /* font-family: ${(props) => props.theme.secondFont}; */
        font-weight: 500;
        font-size: 14px;
        @media (min-width: 525px) {
          margin: 2.5px 0;
          text-align: right;
        }
      }
    }
    /* hr {
      border: 2px solid ${(props) => props.theme.black};
      background: ${(props) => props.theme.black};
      width: 70px;
      margin: 20px auto;
    } */
    /* .made-by {
      font-size: 11px;
      margin-bottom: 75px;
      text-align: center;
    } */
  }
  .social-row-small {
    margin: 25px auto 0;
    display: flex;
    flex-direction: row;
    .social-link {
      display: flex;
      svg,
      .tiktok {
        width: 25px;
        height: 25px;
      }
      .tiktok {
        margin-left: 2.5px;
      }
      svg:nth-child(1) {
        margin: auto 5px;
      }
    }
    @media (min-width: 690px) {
      display: none;
    }
  }
`;

const Footer = () => (
  <StaticQuery
    query={graphql`
      {
        pallasLogo: file(relativePath: { eq: "logos/pallas.png" }) {
          childImageSharp {
            gatsbyImageData(height: 200, layout: FULL_WIDTH)
          }
        }
        leviLogo: file(relativePath: { eq: "logos/levi.png" }) {
          childImageSharp {
            gatsbyImageData(height: 200, layout: FULL_WIDTH)
          }
        }
        kilpisLogo: file(relativePath: { eq: "logos/kilpis.png" }) {
          childImageSharp {
            gatsbyImageData(height: 200, layout: FULL_WIDTH)
          }
        }
        cyclistsLogo: file(
          relativePath: { eq: "logos/welcome_cyclist_logo.png" }
        ) {
          childImageSharp {
            gatsbyImageData(height: 200, layout: FULL_WIDTH)
          }
        }
      }
    `}
    render={(data) => (
      <ThemeProvider theme={theme}>
        <FooterStyles>
          <h3 className="partners-title">Yhteistyökumppanimme</h3>
          <div className="footer-logo-row">
            <a
              href="https://www.luontoon.fi/kilpisjarvenluontokeskus"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                image={data.kilpisLogo.childImageSharp.gatsbyImageData}
                className="footer-logo-kilpis"
                alt="image"
              />
            </a>
            <GatsbyImage
              image={data.cyclistsLogo.childImageSharp.gatsbyImageData}
              className="footer-logo"
              alt="image"
            />
            <a
              href="https://www.luontoon.fi/pallas-yllastunturi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                image={data.pallasLogo.childImageSharp.gatsbyImageData}
                className="footer-logo-pallas"
                alt="image"
              />
            </a>
            <a
              href="https://www.levi.fi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                image={data.leviLogo.childImageSharp.gatsbyImageData}
                className="footer-logo"
                alt="image"
              />
            </a>
          </div>
          <div className="footer-info-row">
            {/* <h5 className="contact-us">Contact us</h5> */}
            <div className="contact-column">
              <div className="contact-with">
                <FontAwesomeIcon icon={faEnvelopeSquare} />
                <a
                  className="contact-email"
                  href="mailto:discoverlapland@gmail.com"
                >
                  discoverlapland@gmail.com
                </a>
              </div>
              <div className="contact-with">
                <FontAwesomeIcon icon={faPhoneSquare} />
                <p className="contact-phone">+358 40 7724124</p>
              </div>
            </div>
            {/* <hr /> */}
            <div className="social-row">
              <a
                href="https://www.instagram.com/discoverlapland/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              {/* <a
                href="https://www.tripadvisor.se/Attraction_Review-g7382980-d13794686-Reviews-Discover_Lapland-Sirkka_Levi_Lapland.html"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                <FontAwesomeIcon icon={faTripadvisor} />
              </a> */}
              <a
                href="https://www.facebook.com/DiscoverLapland.fi/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
              <a
                href="https://www.tiktok.com/@discoverlapland/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                <img alt="tiktok" src={TikTok} className="tiktok" />
              </a>
            </div>
            <div className="terms-column">
              <Link to="/varaus-ja-peruutusehdot" className="terms-link">
                Varaus- ja peruutusehdot
              </Link>
              <Link to="/rekisteri-ja-tietosuojaseloste" className="terms-link">
                Rekisteri- ja tietosuojaseloste
              </Link>
            </div>
            {/* <a
        href="https://instagram.com/vicheman/"
        target="_blank"
        rel="noopener noreferrer"
        className="made-by"
      >
        Coded, designed and photographed
        <br />
        by Victor Engström
      </a> */}
          </div>
          <div className="social-row-small">
            <a
              href="https://www.instagram.com/discoverlapland/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            {/* <a
              href="https://www.tripadvisor.se/Attraction_Review-g7382980-d13794686-Reviews-Discover_Lapland-Sirkka_Levi_Lapland.html"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <FontAwesomeIcon icon={faTripadvisor} />
            </a> */}
            <a
              href="https://www.facebook.com/DiscoverLapland.fi/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <FontAwesomeIcon icon={faFacebookSquare} />
            </a>
            <a
              href="https://www.tiktok.com/@discoverlapland/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <img alt="tiktok" className="tiktok" src={TikTok} />
            </a>
          </div>
        </FooterStyles>
      </ThemeProvider>
    )}
  />
);

export default Footer;
