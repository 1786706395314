/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled, { ThemeProvider } from 'styled-components';

import theme from '../../stylesheets/theme';
import Logo from '../../images/logos/Black.svg';
import {
  vaellukset,
  // maastopyoraily,
  hiihtovaellukset,
  // vapaalasku,
} from '../../information/camps';

import BurgerMenu from './BurgerMenu';

const NavStyles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 999;
  position: fixed;
  .nav-bar {
    width: 100%;
    height: 55px;
    margin: 0 auto;
    padding: 0 2vw;

    background: ${(props) => props.theme.white};

    list-style: none;
    display: flex;
    flex-direction: row;
    z-index: 2;
    .burger-container {
      min-width: 25%;
      margin: auto 0;
      padding: 5px 15px;
      display: flex;
      @media (min-width: 1300px) {
        display: none;
      }
      svg {
        margin-left: auto;
        width: 20px;
        height: 20px;
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }
    a,
    .p-link {
      margin: auto 0;
      padding: 10px 20px;
      font-size: 14px;
      font-family: ${(props) => props.theme.firstFont};
      font-weight: 700;
      letter-spacing: 0.3px;
      text-align: center;
      cursor: pointer;
    }
    .nav-links {
      display: none;
      @media (min-width: 1300px) {
        display: flex;
        margin: auto 0;
      }
    }
    .middle-col-container {
      position: absolute;
      height: 55px;
      width: 96vw;
      display: flex;
      flex-direction: row;
      pointer-events: none;
      .trip-group {
        margin: auto;
        display: flex;
        flex-direction: row;
        height: 55px;
        pointer-events: auto;
      }
    }
    .info-group {
      display: none;
      @media (min-width: 1300px) {
        margin: auto 0 auto auto;
        display: flex;
        flex-direction: row;
      }
    }
    img {
      margin: 5px 0;
      height: 30px;
      @media (min-width: 400px) {
        height: 35px;
      }
    }
    .title {
      margin: auto auto auto 0;
      padding: 0 20px;
      align-self: center;
      z-index: 1000;
      @media (min-width: 1300px) {
        margin: auto auto auto 0;
      }
    }
    .dropdown {
      display: none;
      flex-direction: column;
      margin: auto 0;
      @media (min-width: 1300px) {
        display: flex;
        margin: auto 0 auto 0;
      }
      .dropdown-content {
        display: none;
        flex-direction: column;
        margin: 35px auto 0 5px;
        z-index: 3;
        position: fixed;
        background: ${(props) => props.theme.white};
        min-width: 110px;
        max-width: 290px;
        padding: 0 15px 5px;
        .hover-line {
          width: 50px;
          border: 1px solid ${(props) => props.theme.black};
          background: ${(props) => props.theme.black};
          margin: 0 0 5px;
          pointer-events: inherit;
        }
        a,
        .temp {
          padding: 5px 15px 5px 0;
          text-align: left;
        }
        .event-title {
          font-size: 14px;
          font-weight: 600;
          text-align: left;
        }
        .event-date {
          font-size: 14px;
          font-weight: 300;
          text-align: left;
        }
      }
      &:hover .dropdown-content {
        display: flex;
      }
    }
  }

  .fixed {
    width: 100%;
    z-index: 1;
  }
`;

const Nav = ({ toggleNoScroll }) => {
  const [viewBurger, setViewBurger] = useState(false);

  return (
    <div className="fixed">
      <TransitionGroup exit={false}>
        {viewBurger ? (
          <CSSTransition
            timeout={500}
            classNames="slide-down"
            appear
            exit
            key="booking-content"
          >
            <BurgerMenu
              toggleNoScroll={toggleNoScroll}
              toggleBurger={setViewBurger}
            />
          </CSSTransition>
        ) : null}
      </TransitionGroup>
      <ThemeProvider theme={theme}>
        <NavStyles>
          <div className="nav-bar">
            <Link to="/" className="title col-1">
              <img src={Logo} alt="" />
            </Link>
            <div className="burger-container">
              <FontAwesomeIcon
                icon={faBars}
                onClick={() => {
                  setViewBurger(!viewBurger);
                  toggleNoScroll(true);
                }}
              />
            </div>
            <div className="middle-col-container">
              <div className="trip-group col-2">
                <div className="dropdown">
                  <div className="p-link">Vaellukset</div>
                  <div className="dropdown-content">
                    <hr className="hover-line" />
                    {vaellukset.map((camp) => (
                      <Link to={camp.link} key={camp.title}>
                        <p className="event-title">{camp.title}</p>
                        {camp.dates.map((date) => (
                          <p className="event-date" key={date}>
                            {date}
                          </p>
                        ))}
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="dropdown">
                  <div className="p-link">Hiihtovaellukset</div>
                  <div className="dropdown-content">
                    <hr className="hover-line" />
                    {hiihtovaellukset.map((camp) => (
                      <Link to={camp.link} key={camp.title}>
                        <p className="event-title">{camp.title}</p>
                        {camp.dates.map((date) => (
                          <p className="event-date" key={date}>
                            {date}
                          </p>
                        ))}
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="dropdown">
                  <div className="p-link">Maastopyöräily</div>
                  <div className="dropdown-content">
                    <hr className="hover-line" />
                    {/* {maastopyoraily.map((camp) => (
                      <Link to={camp.link} key={camp.title}>
                        <p className="event-title">{camp.title}</p>
                        {camp.dates.map((date) => (
                          <p className="event-date" key={date}>
                            {date}
                          </p>
                        ))}
                      </Link>
                    ))} */}
                    <p className="event-title temp">Lisätiedot tulevat pian</p>
                  </div>
                </div>
                <div className="dropdown">
                  <div className="p-link">Vapaalasku</div>
                  <div className="dropdown-content">
                    <hr className="hover-line" />
                    {/* {vapaalasku.map((camp) => (
                      <Link to={camp.link} key={camp.title}>
                        <p className="event-title">{camp.title}</p>
                        {camp.dates.map((date) => (
                          <p className="event-date" key={date}>
                            {date}
                          </p>
                        ))}
                      </Link>
                    ))} */}
                    <p className="event-title temp">Lisätiedot tulevat pian</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="info-group col-3">
              <Link to="/usein-kysytyt-kysymykset" className="nav-links">
                Usein kysytyt kysymykset
              </Link>
              <Link to="/tietoa-meista" className="nav-links">
                Tietoa meistä
              </Link>
            </div>
          </div>
        </NavStyles>
      </ThemeProvider>
    </div>
  );
};

export default Nav;
